var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Automatic Column Widths", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Use "),
        _c("code", [_vm._v("flex-1")]),
        _vm._v(
          " instead of an explicit width on your columns to have them size automatically to fill the row"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "flex mb-4" }, [
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-1 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("flex-1"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-1 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("flex-1"),
              ]),
            ]
          ),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<!-- Full width column -->\n<div class="flex mb-4">\n  <div class="flex-1 bg-grid-color h-12"></div>\n</div>\n\n<!-- Five columns -->\n<div class="flex mb-4">\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n</div>\n\n<!-- Seven columns -->\n<div class="flex mb-4">\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n</div>\n\n<!-- Eleven columns -->\n<div class="flex mb-4">\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n  <div class="flex-1 bg-grid-color h-12"></div>\n  <div class="flex-1 bg-grid-color-secondary h-12"></div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }