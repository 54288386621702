var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Mixed Column Sizes", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Mix different percentage width utilities to build mixed size grids"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "flex mb-4" }, [
          _c("div", { staticClass: "w-3/4 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-3/4")]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/4 bg-grid-color-secondary h-12 flex" },
            [_c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/4")])]
          ),
        ]),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "w-1/5 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/5")]),
          ]),
          _c(
            "div",
            { staticClass: "w-3/5 bg-grid-color-secondary h-12 flex" },
            [_c("span", { staticClass: "flex m-auto" }, [_vm._v("w-3/5")])]
          ),
          _c("div", { staticClass: "w-1/5 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/5")]),
          ]),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<!-- Narrower side column -->\n<div class="flex mb-4">\n  <div class="w-3/4 bg-grid-color h-12"></div>\n  <div class="w-1/4 bg-grid-color-secondary h-12"></div>\n</div>\n\n<!-- Wide center column -->\n<div class="flex">\n  <div class="w-1/5 bg-grid-color h-12"></div>\n  <div class="w-3/5 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/5 bg-grid-color h-12"></div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }