var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Column Order", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Use "),
        _c("code", [_vm._v("flex-row-reverse")]),
        _vm._v(
          " to reverse column order. Useful for two-column responsive layouts where the column on right should appear first on smaller screens"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "flex md:flex-row-reverse flex-wrap" }, [
          _c(
            "div",
            {
              staticClass:
                "w-full md:w-3/4 bg-grey p-4 text-center text-grey-lighter",
            },
            [_vm._v("1")]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-full md:w-1/4 bg-grey-light p-4 text-center text-grey-darker",
            },
            [_vm._v("2")]
          ),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="flex md:flex-row-reverse flex-wrap">\n  <div class="w-full md:w-3/4 bg-grey p-4 text-center text-grey-lighter">1</div>\n  <div class="w-full md:w-1/4 bg-grey-light p-4 text-center text-grey-darker">2</div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }