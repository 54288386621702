var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Simple Offsets", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Use auto margin utilities like "),
        _c("code", [_vm._v("ml-auto")]),
        _vm._v(" and "),
        _c("code", [_vm._v("mr-auto")]),
        _vm._v(" to offset columns in a row"),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "flex flex-wrap" }, [
          _c("div", { staticClass: "w-1/3 ml-auto bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "flex m-auto" }, [
              _c("span", { staticClass: "sm:inline hidden mr-2" }, [
                _vm._v("w-1/3"),
              ]),
              _c("span", [_vm._v("ml-auto")]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/3 mr-auto bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "flex m-auto" }, [
                _c("span", { staticClass: "sm:inline hidden mr-2" }, [
                  _vm._v("w-1/3"),
                ]),
                _c("span", [_vm._v("mr-auto")]),
              ]),
            ]
          ),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="flex flex-wrap">\n  <div class="w-1/3 ml-auto bg-grid-color h-12"></div>\n  <div class="w-1/3 mr-auto bg-grid-color-secondary h-12"></div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }