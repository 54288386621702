var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Wrapping Columns", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Add "),
        _c("code", [_vm._v("flex-wrap")]),
        _vm._v(
          " to your column container to allow columns to wrap when they run out of room"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "flex flex-wrap -mb-4" }, [
          _c(
            "div",
            { staticClass: "w-1/3 mb-4 bg-grid-color-secondary h-12 flex" },
            [_c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")])]
          ),
          _c("div", { staticClass: "w-1/3 mb-4 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/3 mb-4 bg-grid-color-secondary h-12 flex" },
            [_c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")])]
          ),
          _c("div", { staticClass: "w-1/3 mb-4 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/3 mb-4 bg-grid-color-secondary h-12 flex" },
            [_c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")])]
          ),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="flex flex-wrap -mb-4">\n  <div class="w-1/3 mb-4 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/3 mb-4 bg-grid-color h-12"></div>\n  <div class="w-1/3 mb-4 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/3 mb-4 bg-grid-color h-12"></div>\n  <div class="w-1/3 mb-4 bg-grid-color-secondary h-12"></div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }