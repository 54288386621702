var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Responsive Grids", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Use the responsive variants of the width utilities to build responsive grid layouts"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap mb-base" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mr-4 mb-4",
                attrs: { type: "border" },
                on: {
                  click: function ($event) {
                    _vm.colClass = "w-full"
                  },
                },
              },
              [_vm._v("All (w-full)")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "mr-4 mb-4",
                attrs: { type: "border" },
                on: {
                  click: function ($event) {
                    _vm.colClass = "w-1/2"
                  },
                },
              },
              [_vm._v("sm:w-1/2")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "mr-4 mb-4",
                attrs: { type: "border" },
                on: {
                  click: function ($event) {
                    _vm.colClass = "w-1/3"
                  },
                },
              },
              [_vm._v("md:w-1/3")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "mr-4 mb-4",
                attrs: { type: "border" },
                on: {
                  click: function ($event) {
                    _vm.colClass = "w-1/4"
                  },
                },
              },
              [_vm._v("lg:w-1/4")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "mr-4 mb-4",
                attrs: { type: "border" },
                on: {
                  click: function ($event) {
                    _vm.colClass = "w-1/6"
                  },
                },
              },
              [_vm._v("xl:w-1/6")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "flex flex-wrap" }, [
          _c("div", {
            staticClass: "mb-4 h-12 bg-grid-color",
            class: _vm.colClass,
          }),
          _c("div", {
            staticClass: "mb-4 h-12 bg-grid-color-secondary",
            class: _vm.colClass,
          }),
          _c("div", {
            staticClass: "mb-4 h-12 bg-grid-color",
            class: _vm.colClass,
          }),
          _c("div", {
            staticClass: "mb-4 h-12 bg-grid-color-secondary",
            class: _vm.colClass,
          }),
          _c("div", {
            staticClass: "mb-4 h-12 bg-grid-color",
            class: _vm.colClass,
          }),
          _c("div", {
            staticClass: "mb-4 h-12 bg-grid-color-secondary",
            class: _vm.colClass,
          }),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="flex flex-wrap">\n  <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-grid-color"></div>\n  <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-grid-color-secondary"></div>\n  <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-grid-color"></div>\n  <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-grid-color-secondary"></div>\n  <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/6 mb-4 bg-grid-color"></div>\n  <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/6 mb-4 bg-grid-color-secondary"></div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }