var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Basic Grids", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Use the existing "),
        _c(
          "a",
          {
            attrs: {
              href: "https://tailwindcss.com/docs/flexbox-display",
              rel: "nofollow",
            },
          },
          [_vm._v("Flexbox")]
        ),
        _vm._v(" and "),
        _c(
          "a",
          {
            attrs: {
              href: "https://tailwindcss.com/docs/width",
              rel: "nofollow",
            },
          },
          [_vm._v("percentage width")]
        ),
        _vm._v(" utilities to construct basic grids"),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "flex mb-4" }, [
          _c("div", { staticClass: "w-full bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-full"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "w-1/2 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/2"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/2 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/2"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "w-1/3 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/3"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/3 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/3"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/3 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/3"),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex mb-4" }, [
          _c("div", { staticClass: "w-1/4 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/4"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/4 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/4"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/4 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/4"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/4 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/4"),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex mb-4" }, [
          _c("div", { staticClass: "w-1/5 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/5"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/5 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/5"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/5 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/5"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/5 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/5"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/5 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/5"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "w-1/6 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/6"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/6 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/6"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/6 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/6"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/6 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/6"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/6 bg-grid-color-secondary h-12 flex" },
            [
              _c("span", { staticClass: "sm:flex hidden m-auto" }, [
                _vm._v("w-1/6"),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-1/6 bg-grid-color h-12 flex" }, [
            _c("span", { staticClass: "sm:flex hidden m-auto" }, [
              _vm._v("w-1/6"),
            ]),
          ]),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<!-- Full width column -->\n<div class="flex mb-4">\n  <div class="w-full bg-grid-color h-12"></div>\n</div>\n<!-- Two columns -->\n<div class="flex mb-4">\n  <div class="w-1/2 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/2 bg-grid-color h-12"></div>\n</div>\n\n<!-- Three columns -->\n<div class="flex mb-4">\n  <div class="w-1/3 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/3 bg-grid-color h-12"></div>\n  <div class="w-1/3 bg-grid-color-secondary h-12"></div>\n</div>\n\n<!-- Four columns -->\n<div class="flex mb-4">\n  <div class="w-1/4 bg-grid-color h-12"></div>\n  <div class="w-1/4 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/4 bg-grid-color h-12"></div>\n  <div class="w-1/4 bg-grid-color-secondary h-12"></div>\n</div>\n\n<!-- Five columns -->\n<div class="flex mb-4">\n  <div class="w-1/5 bg-grid-color h-12"></div>\n  <div class="w-1/5 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/5 bg-grid-color h-12"></div>\n  <div class="w-1/5 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/5 bg-grid-color h-12"></div>\n</div>\n\n<!-- Six columns -->\n<div class="flex">\n  <div class="w-1/6 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/6 bg-grid-color h-12"></div>\n  <div class="w-1/6 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/6 bg-grid-color h-12"></div>\n  <div class="w-1/6 bg-grid-color-secondary h-12"></div>\n  <div class="w-1/6 bg-grid-color h-12"></div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }