var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "tailwind-grid-demo" } },
    [
      _c("grid-basic-grid"),
      _c("grid-responsive-grids"),
      _c("grid-mixed-column-sizes"),
      _c("grid-wrapping-columns"),
      _c("grid-column-spacing"),
      _c("grid-auto-column-width"),
      _c("grid-simple-offset"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }