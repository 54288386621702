var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Column Spacing", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Add a negative horizontal margin like "),
        _c("code", [_vm._v("-mx-2")]),
        _vm._v(
          " to your column container and an equal horizontal padding like "
        ),
        _c("code", [_vm._v("px-2")]),
        _vm._v(" to each column to add gutters"),
      ]),
      _c("p", [
        _vm._v("To prevent horizontal scrolling in full width layouts, add "),
        _c("code", [_vm._v("overflow-hidden")]),
        _vm._v(
          " to another parent container, or compensate for the negative margin with matching horizontal padding"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "px-2" }, [
          _c("div", { staticClass: "flex -mx-2" }, [
            _c("div", { staticClass: "w-1/3 px-2" }, [
              _c("div", { staticClass: "bg-grid-color-secondary h-12 flex" }, [
                _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")]),
              ]),
            ]),
            _c("div", { staticClass: "w-1/3 px-2" }, [
              _c("div", { staticClass: "bg-grid-color h-12 flex" }, [
                _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")]),
              ]),
            ]),
            _c("div", { staticClass: "w-1/3 px-2" }, [
              _c("div", { staticClass: "bg-grid-color-secondary h-12 flex" }, [
                _c("span", { staticClass: "flex m-auto" }, [_vm._v("w-1/3")]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="px-2">\n  <div class="flex -mx-2">\n    <div class="w-1/3 px-2">\n      <div class="bg-grid-color-secondary h-12"></div>\n    </div>\n    <div class="w-1/3 px-2">\n      <div class="bg-grid-color h-12"></div>\n    </div>\n    <div class="w-1/3 px-2">\n      <div class="bg-grid-color-secondary h-12"></div>\n    </div>\n  </div>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }